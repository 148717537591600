<template>
  <div class="home">
    <el-backtop :visibility-height="20"></el-backtop>
    <div class="website_header">
      <img src="@/assets/logo2.png" :style="{ display: pageWidth < 700 ? 'none' : 'flex'}" alt="" />
      <h1 :style="{ fontSize: pageWidth < 700 ? '32px' : '64px'}">共享收益的拼车生活平台</h1>
    </div>

    <div class="home_txt" :style="{
        fontSize: pageWidth < 700 ? '17px' : '24px',
      }">
      趣出行，不仅仅是一款拼车应用，更是一场拼车生活的革命。我们重新定义了拼车，将其升华为一种智能、高效、可盈利的生活方式。在趣出行，我们致力于构建一个真正的拼车顺风车生态系统，让出行变得更加便捷、经济和有趣。
    </div>

    <div :class=" pageWidth < 700 ? 'home_adv_content1_media' : 'home_adv_content1'">
      <div>
        <h4>为车主和乘客创造更多价值</h4>
        <p>
          在趣出行，车主不再需要绕路来接送乘客，这意味着更省心、更省时。乘客只需按照座位数量支付费用，不必为绕路而多付钱。我们采用海星模式，基于线路和圈子，构建本地生活服务圈，让拼车更加智能化和社交化。
        </p>
      </div>
      <div>
        <h4>去中心化的信息传播</h4>
        <p>
          我们打破了传统信息传播中的垄断收费行为，通过去中心化的信息传播方式，让信息的传播价值回归于传播者本身。这不仅降低了人们的生活成本，还为用户提供了分享和传播有价值信息的机会，并共享收益。
        </p>
      </div>
      <div :style="{
        display: pageWidth < 700 ? 'block' : 'none',
      }" >
        <h4>基于区块链技术的通证经济</h4>
        <p>
          趣出行采用区块链技术和通证经济学，重组了生产关系，让出行变得更经济、更有趣，同时也为用户提供了创造性的赚钱机会。通过趣宝盒等工具，用户可以参与到共享经济中，享受拼车生活所带来的实际经济回报。<br />
          趣出行，不仅仅是一个拼车平台，更是一个共享收益的生活方式。我们的使命是让出行更轻松、更智能，让生活更美好、更省钱。开启拼车生活的全新篇章，体验更有趣的出行方式，创造更多的共享价值。
        </p>
      </div>
    </div>

    <div class="home_adv_content2" :style="{ display: pageWidth < 700 ? 'none' : 'block'}">
        <h4>基于区块链技术的通证经济</h4>
        <p>
          趣出行采用区块链技术和通证经济学，重组了生产关系，让出行变得更经济、更有趣，同时也为用户提供了创造性的赚钱机会。通过趣宝盒等工具，用户可以参与到共享经济中，享受拼车生活所带来的实际经济回报。<br />
          趣出行，不仅仅是一个拼车平台，更是一个共享收益的生活方式。我们的使命是让出行更轻松、更智能，让生活更美好、更省钱。开启拼车生活的全新篇章，体验更有趣的出行方式，创造更多的共享价值。
        </p>
      </div>

    <div class="home_map" :style="{ alignItems: pageWidth < 700 ? 'center': 'normal'}">
      <img src="@/assets/map.png" :class="pageWidth < 700 ? 'home_map_img_media' : 'home_map_img'" alt="" />
      <div class="home_flex" :style="{
        justifyContent: pageWidth < 700 ? 'space-evenly' : 'space-between',
      }" alt="" >
        <div :class="pageWidth < 700 ? 'home_map_b_media' : 'home_map_b'">
          <div>
            <h4>50</h4>
            <p>万</p>
          </div>
          <p class="home_map_txt">平台车主</p>
        </div>
        <div :class="pageWidth < 700 ? 'home_map_b_media' : 'home_map_b'">
          <div>
            <h4>190</h4>
            <p>万</p>
          </div>
          <p class="home_map_txt">实名认证持币用户</p>
        </div>
        <div :class="pageWidth < 700 ? 'home_map_b_media' : 'home_map_b'">
          <div>
            <h4>600</h4>
            <p>万</p>
          </div>
          <p class="home_map_txt">注册用户</p>
        </div>
        <div :class="pageWidth < 700 ? 'home_map_b_media' : 'home_map_b'">
          <div>
            <h4>10000</h4>
            <p>条</p>
          </div>
          <p class="home_map_txt">固定线路</p>
        </div>
      </div>

    </div>

    <div class="home_advantage">
      <h3 class="home_title" :style="{ fontSize: pageWidth < 700 ? '27px' : '36px' }">我们的优势</h3>
      <div :class=" pageWidth < 700 ? 'home_adv_content1_media' : 'home_adv_content1'">
        <div>
          <h4>环保出行－节能减排</h4>
          <p>
            趣出行积极倡导共享空座，这不仅降低了个人出行成本，还在实际行动中支持了绿色出行和可持续发展。我们平台促进了低碳、绿色出行，帮助改善了环境质量，共同为构建更美好的家园作出一份微薄而重要的贡献。趣出行的用户成为环保行动的一部分，积极参与降低污染，维护大自然的健康，助力可持续的未来。
          </p>
        </div>
        <div>
          <h4>海星模式 - 拼车智能化</h4>
          <p>
            我们引以为傲的海星模式重新定义了拼车。它基于线路和圈子，使得每条线路和每个社交圈子都能实现去中心化，去中介化的信息传播，从而实现低成本高效率的出行。这个模式不仅为用户提供了更便捷的出行方式，还为信息传播的参与者提供了获利的机会，确保各方的利益得到保障。
          </p>
        </div>
        <div>
          <h4>信息透明－区块链的信任保证</h4>
          <p>
            我们充分利用区块链技术的特性，如不可篡改、可追溯和公开透明，来确保数据的安全和透明。用户的数据将以分布式方式存储，不仅保护了用户的隐私，还让数据真正回归给用户本身，让用户能够更好地掌握自己的信息。
          </p>
        </div>
        <div>
          <h4>去中介化 - 公平市场</h4>
          <p>
            通过去除传统中介，趣出行创建了一个公平竞争的市场，让信息传播的参与者能够从中受益，同时保障了各方的权益。
          </p>
        </div>
        <div>
          <h4>安全保障－用户权益第一</h4>
          <p>
            趣出行采用多种安全措施，包括一键报警、匿名举报以及职业认证，以确保用户的出行安全和体验。
          </p>
        </div>
        <div>
          <h4>社交互动－有温度拼车方式</h4>
          <p>
            趣出行鼓励用户在社交圈子内分享出行信息，增强了社区感和用户之间的互动。
          </p>
        </div>
        <div>
          <h4>生态共建－权益共享</h4>
          <p>趣出行通过激励计划，鼓励用户积极参与平台建设，分享生态成果。</p>
        </div>
        <div>
          <h4>经济共赢 - 让您的出行更具价值</h4>
          <p>
            对于车主和乘客而言，趣出行提供了更经济实惠的出行选择。车主可以分享空座位并赚取奖励，而乘客可以按需拼车，减少了个人出行成本。
          </p>
        </div>
      </div>
    </div>

    <div class="home_condition">
      <h3 class="home_title" :style="{ fontSize: pageWidth < 700 ? '27px' : '36px' }">行业现状</h3>

      <div :class=" pageWidth < 700 ? 'home_adv_content1_media' : 'home_adv_content1'">
        <div>
          <h4>市场垄断</h4>
          <p>
            当前，出行市场普遍存在大资本的垄断现象。这些资本投入巨大，导致出行成本飙升，用户信息的隐私和安全也面临威胁。趣出行共享拼车应运而生，破解垄断，为用户创造更多价值。
          </p>
        </div>
        <div>
          <h4>利益失衡</h4>
          <p>
            垄断企业的出现带来了高昂的出行费用，用户成本不断攀升，而出行行业的利润却主要流向这些垄断企业，导致利益失衡。趣出行的目标是恢复平衡，将利益还给用户，降低用户的出行成本。
          </p>
        </div>
        <div>
          <h4>安全侵害</h4>
          <p>
            垄断企业的盈利追求使其忽视了用户信息的安全。审核不严格、运营不力、数据隐瞒等问题逐渐暴露，用户的权益和安全受到侵害。趣出行致力于保障用户的安全和隐私。
          </p>
        </div>
        <div>
          <h4>数据私用</h4>
          <p>
            垄断企业不仅获得用户信息，还私自挪用、出售用户数据，这导致用户信息泄露和被滥用，损害了用户的合法权益。趣出行将用户信息保护放在首位，杜绝数据滥用行为。
          </p>
        </div>
        <div>
          <h4>服务不规范</h4>
          <p>
            出行行业普遍存在服务质量参差不齐的问题。一些司机或平台未能提供良好的服务体验，这影响了用户的出行满意度。趣出行通过激励机制鼓励司机和用户提供高质量的拼车服务，提高行业规范。
          </p>
        </div>
        <div>
          <h4>废弃资源浪费</h4>
          <p>
            传统出行模式中，车辆资源浪费严重，私家车利用率低，交通拥堵和资源浪费问题突出。趣出行通过拼车共享模式，充分利用现有资源，减少了废弃资源的浪费，实现了更加环保和可持续的出行方式。
          </p>
        </div>
        <div>
          <h4>信息不对称</h4>
          <p>
            在传统出行市场中，信息不对称是常见问题，用户难以获得准确和充分的出行信息。趣出行通过去中心化信息传播，打破了信息垄断，使用户能够更容易地获得所需信息，提高了市场的透明度。
          </p>
        </div>
        <div>
          <h4>缺乏激励机制</h4>
          <p>
            出行行业中，缺乏激励机制，司机和用户往往没有积极地参与动力。趣出行通过通证经济模型，为参与者提供了激励，鼓励他们积极参与生态建设，共同创造更好的出
            行生活。
          </p>
        </div>
      </div>
    </div>

    <div class="home_qcx">
      <h1 :style="{ fontSize: pageWidth < 700 ? '27px' : '36px' }">如何深度参与到趣出行</h1>

      <div class="home_qcx_join">
        <h2 :style="{ fontSize: pageWidth < 700 ? '20px' : '24px' }">1. 安装趣宝盒，参与行程上链</h2>
        <div :class=" pageWidth < 700 ? 'home_adv_content1_media' : 'home_adv_content1'">
          <div>
            <h4>安装趣宝盒</h4>
            <p>
              将趣宝盒安装在您的车上，它将记录您的行程和数据，为您赚取实际收益提供支持。
            </p>
          </div>
          <div>
            <h4>发布行程</h4>
            <p>
              在趣出行平台上发布您的行程信息，与其他用户分享您的空座位，为他人提供拼车机会。
            </p>
          </div>
          <div>
            <h4>邀请其他车主</h4>
            <p>
              邀请更多的车主加入平台，一起享受驾车收益，您将获得更高的个人奖励基数。
            </p>
          </div>
        </div>
        <div class="home_adv_content2">
          <h4 :style="{ fontSize: pageWidth < 700 ? '16px' : '22px' }">行程激励</h4>
          <p>
            通过安装趣宝盒，发布行程并实际执行行程，车主将根据平台的激励算法获得CPLE通证奖励。奖励会根据个人质押量、周围订单数等因素进行计算。行程激励算法细则如下：
          </p>
          <div style="margin-top: 10px">
            名词解释：
            <ul>
              <li>基础质押量：600；（基于Polygon链的CPLE，定期调整）；</li>
              <li>个人奖励基数：2+最近21天内推广激活趣宝盒数量*2；</li>
              <li>5公里内基础订单数：100 ；</li>
              <li>最高系数：根据平台总质押量进行调整，如图所示：</li>
            </ul>

            <table :class="pageWidth < 700 ? 'home_qcx_join_table_media' : 'home_qcx_join_table'">
              <tr class="table_title_tr">
                <td>全网质押总量</td>
                <td>单次行程产出最高量</td>
                <td class="table_td_border">每天产出最高量</td>
              </tr>
              <tr>
                <td style="height: 35px">< 600w</td>
                <td>1/200</td>
                <td class="table_td_border">1/100</td>
              </tr>
              <tr>
                <td>< 900w</td>
                <td>1/300</td>
                <td class="table_td_border">1/150</td>
              </tr>
              <tr>
                <td style="height: 35px">< 1200w</td>
                <td>1/400</td>
                <td class="table_td_border">1/200</td>
              </tr>
              <tr>
                <td>< 1500w</td>
                <td>1/500</td>
                <td class="table_td_border">1/250</td>
              </tr>
              <tr>
                <td>< 1800w</td>
                <td>1/600</td>
                <td class="table_td_border">1/300</td>
              </tr>
              <tr>
                <td>< 3000w</td>
                <td>1/1000</td>
                <td class="table_td_border">1/500</td>
              </tr>
              <tr>
                <td>< 6000w</td>
                <td>1/2000</td>
                <td class="table_td_border">1/1000</td>
              </tr>
              <tr>
                <td style="border-bottom: none">< 9000w</td>
                <td style="border-bottom: none">1/3000</td>
                <td style="border-right: none; border-bottom: none">1/1500</td>
              </tr>
            </table>
          </div>
          <p style="margin-top: 10px">
            在行程中：趣宝盒每上报一次数据时，计算一次奖励，<span
              style="font-weight: bold"
              >单次上报奖励量 = 个人奖励基数 * [(个人质押量 ➗ 10000)^(1/4)] *
              [(周围5公里正进行中订单数 ➗ 5公里内基础订单数)^(1/2)]</span
            >
          </p>
          <p style="margin-top: 10px">
            行程结束时，累计整个行程奖励，每次奖励的CPLE数量不会超过您CPLE质押量（单人最高有效质押量）的最高系数，并会在行程结束后将激励产生的CPLE同步上链。
          </p>
        </div>
      </div>

      <div class="home_qcx_join" >
        <h2 :style="{ fontSize: pageWidth < 700 ? '20px' : '24px' }">2. 推广趣宝盒，赢取多重收益</h2>
        <div style="margin: 10px 0">
          您不仅可以通过趣宝盒赚取实际的车费收益，还能通过推广趣宝盒获得多重奖励。无论您是车主还是推广者，您的努力都将得到回报。推广趣宝盒，分享我们的理念，让更多人参与到低碳环保的事业中！<span
            style="font-weight: bold"
            >趣宝盒单价：$138（暂定）。推广奖励规则：</span
          >
        </div>

        <table :class="pageWidth < 700 ? 'home_qcx_join_table_media' : 'home_qcx_join_table'">
          <tr class="table_title_tr">
            <td>推广级别</td>
            <td>满足条件</td>
            <td>奖励规则</td>
            <td class="table_td_border">获得奖励</td>
          </tr>
          <tr>
            <td style="height: 35px">直推</td>
            <td>自身已激活趣宝盒</td>
            <td>每分享1台</td>
            <td class="table_td_border">20%</td>
          </tr>
          <tr>
            <td>市运营</td>
            <td>
              1、必须先满足市代，且通过公司渠道订购200台；<br />
              2、趣出行账号质押7万CPLE；<br />
              3、缴纳10万保证金；<br />
              4、拥有75平方米以上的实体店面（附一年租期合同）。<br />
            </td>
            <td>城市内每激活1台</td>
            <td class="table_td_border">5%</td>
          </tr>
          <tr>
            <td style="height: 35px">省运营</td>
            <td>
              1、必须先满足省代，且通过公司渠道订购1000台；<br />
              2、趣出行账号质押7万CPLE；<br />
              3、缴纳10万保证金；<br />
              4、拥有120平方米以上的实体店面（附一年租期合同）。
            </td>
            <td>城市内每激活1台</td>
            <td class="table_td_border">5%</td>
          </tr>
          <tr>
            <td>区代</td>
            <td>
              1、直推≥5人（5直推*5) <br />
              2、团队人数≥60
            </td>
            <td>全网每激活1台</td>
            <td class="table_td_border">6%（全网平分）</td>
          </tr>
          <tr>
            <td>市代</td>
            <td>3个区代</td>
            <td>全网每激活1台</td>
            <td class="table_td_border">3%（全网平分） <br />叠加区代权益</td>
          </tr>
          <tr>
            <td style="border-bottom: none">省代</td>
            <td style="border-bottom: none">3个市代</td>
            <td style="border-bottom: none">全网每激活1台</td>
            <td style="border-right: none; border-bottom: none">
              2%（全网平分）叠加区代/市代权益
            </td>
          </tr>
        </table>
      </div>

      <div class="home_qcx_join">
        <h2 :style="{ fontSize: pageWidth < 700 ? '20px' : '24px' }">3、趣宝盒推广：火财盒计划 创富助梦想起航！</h2>
        <div style="margin: 10px 0">
          为了将趣出行的创新理念传遍大街小巷，带动更多用户参与趣出行的绿色出行生态，特别推起一场精彩的趣宝盒推广盛宴，为您的创富之路加速！
        </div>
        <p style="margin-bottom: 10px">活动规则：</p>
        <p style="margin-bottom: 10px">
          <span style="font-weight: bold; ">活动期限：</span>从2023年9月4日开始，直至激活10万台趣宝盒前有效。
       </p>
        
        <p style="font-weight: bold">奖励规则：
        <ul style="font-weight: 400;">
          <li>邀请用户安装趣宝盒并发布行程。</li>
          <li>每4周作为一个小周期，按照阶梯式累计第1周邀请的每个下级用户在接下来第2、3周的产出和，在第4周生态会给予上级奖励。依此类推，每周邀请越多的用户安装趣宝盒发布行程，接下来两周行程上链收益越高，您就能获得越丰厚的奖励，红红火火发大财，盒盒美美创奇迹。</li>
        </ul></p>

        <table :class="pageWidth < 700 ? 'home_qcx_join_table_media' : 'home_qcx_join_table'">
          <tr>
            <td class="table_title_tr">下级两周累计奖励</td>
            <td>1500+</td>
            <td>1000+</td>
            <td>500+</td>
            <td>300+</td>
            <td>100+</td>
            <td>50+</td>
            <td class="table_td_border">10+</td>
          </tr>
          <tr>
            <td class="table_title_tr">上级奖励</td>
            <td style="border-bottom: none">1500</td>
            <td style="border-bottom: none">900</td>
            <td style="border-bottom: none">400</td>
            <td style="border-bottom: none">200</td>
            <td style="border-bottom: none">65</td>
            <td style="border-bottom: none">30</td>
            <td style="border-right: none; border-bottom: none">5</td>
          </tr>
        </table>

        <div style="margin-top: 15px;">
          <span style="font-weight: bold;">额外奖励：</span>每期活动前10名，表现卓越的推广者将获得额外的奖励，让您的努力更加值得！
          <ul>
            <li>第1名：额外奖励 888.88转出额度</li>
            <li>第2名：额外奖励 188.88转出额度</li>
            <li>第3名：额外奖励 88.88转出额度</li>
            <li>第4-10名：额外奖励 8.88转出额度</li>
          </ul>
          <p><span style="font-weight: bold;">奖励领取：</span>在第四周，您可以领取第一周邀请的用户产出的奖励，在第五周领取第二周邀请的用户产出的奖励，以此类推，循环领取您的努力所得！</p>
        </div>
      </div>

      <div class="home_qcx_join">
        <h2 :style="{ fontSize: pageWidth < 700 ? '20px' : '24px' }">4、参与线路NFT竞拍，成为线路代理人，享受多重回报</h2>
        <p style="margin: 10px 0">通过参与线路NFT竞拍，您可以成为某条线路的代理人，管理着线路内的拼车活动。这不仅会让线路上的人和事更加美好，还能为您带来丰厚的收益。趣出行目前拥有1000条线路，每一条线路都被铸造成3个线路NFT。通过参与线路NFT的竞拍，获得某条线路其中一条NFT，您可以获得该条线路行程流水的1%，作为回报。而如果您幸运地持有该线路的全部3个NFT，您将享受该线路行程流水的3%收益。</p>
        <p>参与线路NFT的竞拍是深度参与趣出行生态系统的独特方式。通过这个过程，您不仅可以享受收益，还可以与其他趣出行社区成员，共同打造更美好的拼车未来。</p>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.scss";

export default {
  name: "Home",
  data(){
    return {
      pageWidth: 0,
    }
  },

  mounted() {
    this.pageWidth = window.innerWidth;
  },
};
</script>
